import { postData } from '../redux/fetchService/baseFetch';
import { getWholeHomeSystem } from '../redux/slice/formData';
import type {
  AuthenticationContext,
  EquipmentRecommendationAPIPayload,
  EquipmentRecommendationsAPIPayload,
  FormAPIPayload,
  FormFields,
  PlaceData,
  ProjectSimulatorFormAPIPayload,
  ProjectsAPIPayload,
  SimulatorAPIPayload,
} from '../typedefs';
import { PaymentOptionType, type ContactEmailType } from '../utils/enums';
import NormalizationUtil from '../utils/normalizationUtil';
import { createApiThunk } from './utils/ApiThunkCreator';

const isDefined = (value: string | undefined) => value !== '' && value !== undefined && value !== null;
const setNumberIfPresent = (value: string | undefined) => (isDefined(value) ? Number(value) : undefined);
const setStringIfPresent = (value: string | undefined) => (isDefined(value) ? value : undefined);
const wrapInArrayIfPresent = (value: any) => (isDefined(value) ? [value] : undefined);

type CreateProjectArgs = {
  partnerSlug: string;
  context?: any;
};

type SaveProjectArgs = {
  formData: FormFields;
  version: string;
  place?: PlaceData;
  selectedProjectId?: number;
  uuid?: string;
  context?: any;
  showCash: boolean;
  partnerSlug: string;
};

type LoadFormArgs = {
  uuid: string;
  partnerSlug: string;
};

export default class ProjectsAPI {
  createProject = async (params: CreateProjectArgs): Promise<{ uuid: string }> => {
    const { partnerSlug, context } = params;
    return postData('projects/create', { partnerSlug, context });
  };

  saveFormAndFetchProjects = async (params: SaveProjectArgs): Promise<ProjectsAPIPayload> => {
    const payload = this.createProjectPayload(params);
    const results = await postData('projects', payload);
    return this.denormalizeRecommendations<ProjectsAPIPayload>(results);
  };

  simulateProjectRecommendation = async (
    formData: FormFields,
    partnerSlug: string,
    authContext: AuthenticationContext,
    version: string,
  ): Promise<SimulatorAPIPayload> => {
    const payload = this.createProjectPayload({
      partnerSlug,
      formData,
      showCash: true,
      version,
    });

    const results = await postData('projects/simulate', payload, authContext);
    return this.denormalizeRecommendations<SimulatorAPIPayload>(results);
  };

  simulateExistingQuote = async (uuid: string, authContext: AuthenticationContext): Promise<SimulatorAPIPayload> => {
    const results = await postData('projects/simulateExisting', { uuid }, authContext);
    return this.denormalizeRecommendations<SimulatorAPIPayload>(results);
  };

  denormalizeRecommendations<T extends ProjectsAPIPayload>(results: T): T {
    // "recommendations" is really a NormalizedEntitiesAPIPayload<EquipmentRecommendationsAPIPayload>
    const { recommendations, ...rest } = results;

    if (!recommendations) {
      return {
        ...rest,
        recommendations: [] as EquipmentRecommendationAPIPayload[],
      } as T;
    }

    const { data } = recommendations as any;
    const denormalizedRecommendations = NormalizationUtil.denormalizeMultipleResults<
      EquipmentRecommendationAPIPayload,
      EquipmentRecommendationsAPIPayload
    >(data, 'equipmentRecommendations');

    return {
      ...rest,
      recommendations: denormalizedRecommendations as EquipmentRecommendationAPIPayload[],
    } as T;
  }

  submitProject = async (uuid: string): Promise<void> => {
    return postData('get-quote', {
      uuid,
    });
  };

  submitContactForm = async (params: { uuid: string; emailType: ContactEmailType }): Promise<void> => {
    return postData('get-contact', params);
  };

  loadFormData = async ({ uuid, partnerSlug }: LoadFormArgs): Promise<FormAPIPayload> => {
    return postData('reload-form', {
      uuid,
      partnerSlug,
    });
  };

  loadFormDataById = async (
    uuid: string,
    authContext: AuthenticationContext,
  ): Promise<ProjectSimulatorFormAPIPayload> => {
    return postData('reload-form/simulator', { uuid }, authContext);
  };

  private createProjectPayload(params: SaveProjectArgs) {
    const { formData, selectedProjectId, uuid, context, partnerSlug, version, showCash } = params;
    const {
      zipCode,
      zipSuffix,
      yearBuilt,
      sqFt,
      roomSqFt,
      minTempDay,
      minTempNight,
      maxTempDay,
      maxTempNight,
      existingSeer,
      streetAddress,
      route,
      email,
      firstName,
      lastName,
      phone,
      homeownerProjectNotes,
      totalSmall,
      totalMedium,
      totalLarge,
      totalXLarge,
      upperSmall,
      upperMedium,
      upperLarge,
      upperXLarge,
      wholeHomeSystem,
      ceilingHeightInFt,
      numStories,
      basementSqFt,
      firstFloorSqFt,
      secondFloorSqFt,
      thirdFloorSqFt,
      numBedrooms,
      numFireplaces,
      paymentOptionType,
      projectFilter,
      ...rest
    } = formData;
    const payload = {
      ...rest,
      uuid,
      partnerSlug,
      projectFilters: wrapInArrayIfPresent(projectFilter),
      wholeHomeSystem: getWholeHomeSystem(formData),
      selectedProjectId,
      zipCode: setNumberIfPresent(zipCode),
      zipCodeSuffix: setNumberIfPresent(zipSuffix || ''),
      sqFt: setNumberIfPresent(sqFt),
      roomSqFt: setNumberIfPresent(roomSqFt),
      yearBuilt: setNumberIfPresent(yearBuilt),
      minTempDay: setNumberIfPresent(minTempDay),
      minTempNight: setNumberIfPresent(minTempNight),
      maxTempDay: setNumberIfPresent(maxTempDay),
      maxTempNight: setNumberIfPresent(maxTempNight),
      existingSeer: setNumberIfPresent(existingSeer),
      streetAddress: setStringIfPresent(streetAddress),
      streetRoute: route,
      email: setStringIfPresent(email),
      firstName: setStringIfPresent(firstName),
      lastName: setStringIfPresent(lastName),
      phone: setStringIfPresent(phone),
      homeownerProjectNotes: setStringIfPresent(homeownerProjectNotes),
      totalSmall: setNumberIfPresent(totalSmall) || 0,
      totalMedium: setNumberIfPresent(totalMedium) || 0,
      totalLarge: setNumberIfPresent(totalLarge) || 0,
      totalXLarge: setNumberIfPresent(totalXLarge) || 0,
      upperSmall: setNumberIfPresent(upperSmall) || 0,
      upperMedium: setNumberIfPresent(upperMedium) || 0,
      upperLarge: setNumberIfPresent(upperLarge) || 0,
      upperXLarge: setNumberIfPresent(upperXLarge) || 0,
      ceilingHeightInFt: setNumberIfPresent(ceilingHeightInFt),
      numStories,
      basementSqFt: setNumberIfPresent(basementSqFt),
      firstFloorSqFt: setNumberIfPresent(firstFloorSqFt),
      secondFloorSqFt: setNumberIfPresent(secondFloorSqFt),
      thirdFloorSqFt: setNumberIfPresent(thirdFloorSqFt),
      numBedrooms: setNumberIfPresent(numBedrooms),
      numFireplaces: setNumberIfPresent(numFireplaces),
      paymentOptionType: showCash ? PaymentOptionType.CASH : PaymentOptionType.LOAN,
      context: context,
      v: version,
    };
    return payload;
  }
}

const api = new ProjectsAPI();

export const createProjectApiThunk = createApiThunk('projectsApi/create', api.createProject);
export const saveProjectApiThunk = createApiThunk('projectsApi/save', api.saveFormAndFetchProjects);
export const reloadProjectApiThunk = createApiThunk('projectsApi/reload', api.loadFormData);
export const submitProjectApiThunk = createApiThunk('projectsApi/submit', api.submitProject);
export const submitContactFormApiThunk = createApiThunk('projectsApi/contact', api.submitContactForm);
