import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';
import type { ModalContent, ModalState } from '../../typedefs';

const initialState: ModalState = {
  show: false,
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal() {
      return initialState;
    },
    openModal(state, action: PayloadAction<ModalContent>) {
      return { ...state, show: true, content: action.payload };
    },
  },
});

export const modalActions = slice.actions;

export const getShowModal = (state: RootState) => state.modal.show;
export const getModalHeader = (state: RootState) => state.modal.content?.header;
export const getModalSize = (state: RootState) => state.modal.content?.size;
export const getModalContent = (state: RootState) => state.modal.content;
export const getModalFullscreen = (state: RootState) => state.modal.content?.fullscreen;
export const getModalEnableContentsPrinting = (state: RootState) => !!state.modal.content?.enableContentsPrinting;

export default slice.reducer;
