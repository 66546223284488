import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { concat, find, reduce, round } from 'lodash-es';
import type { RootState } from '.';
import {
  createProjectApiThunk,
  reloadProjectApiThunk,
  saveProjectApiThunk,
  submitProjectApiThunk,
} from '../../api/ProjectsAPI';
import type {
  Equipment,
  EquipmentRecommendationAPIPayload,
  ProjectsAPIPayload,
  ProjectState,
  SystemConfigurationAPIPayload,
} from '../../typedefs';
import { EMPTY_DICT, EMPTY_LIST, MONTHS_PER_YEAR, QUARTER_TONS_PER_TON, SAVING_LIFESPAN } from '../../utils/constants';
import { HeatPumpDesignType, ProductType, SystemType } from '../../utils/enums';

const initialState: ProjectState = {
  recommendations: [],
  numLoading: 0,
  initialized: false,
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setUUID(state, action: PayloadAction<string>) {
      return { ...state, uuid: action.payload };
    },
    setInitialized(state, action: PayloadAction<boolean>) {
      return { ...state, initialized: action.payload };
    },
    clearProjects(state) {
      return {
        ...state,
        recommendations: [],
      };
    },
    selectProjectId(state, action: PayloadAction<number>) {
      return { ...state, selectedId: action.payload };
    },
    onLoadingStart(state) {
      return { ...state, numLoading: state.numLoading + 1 };
    },
    onLoadingComplete(state) {
      return { ...state, numLoading: state.numLoading - 1 };
    },
  },
  extraReducers(builder) {
    builder.addCase(saveProjectApiThunk.pending, (state, action) => {
      state.pendingSaveRequestId = action.meta.requestId;
    });
    builder.addCase(saveProjectApiThunk.rejected, (state, action) => {
      if (state.pendingSaveRequestId !== action.meta.requestId) {
        return;
      }
      state.pendingSaveRequestId = undefined;
    });
    builder.addCase(saveProjectApiThunk.fulfilled, (state, action) => {
      const { pendingSaveRequestId, selectedId } = state;
      if (pendingSaveRequestId !== action.meta.requestId) {
        return;
      }
      const { payload } = action;
      const { recommendations, uuid, designTemps } = payload as ProjectsAPIPayload;

      const selectedRecommendation = find(
        recommendations,
        (recommendation) => recommendation.recommendationId === selectedId,
      );

      return {
        ...state,
        pendingSaveRequestId: undefined,
        recommendations: recommendations,
        designTemps,
        selectedId: selectedRecommendation?.recommendationId,
        uuid: uuid || state.uuid,
      };
    });
    builder.addCase(createProjectApiThunk.fulfilled, (state, action) => {
      return {
        ...state,
        uuid: action.payload.uuid || state.uuid,
      };
    });
    builder.addCase(submitProjectApiThunk.pending, (state, action) => {
      state.pendingSubmitRequestId = action.meta.requestId;
    });
    builder.addCase(reloadProjectApiThunk.fulfilled, (state, action) => {
      const { selectedProjectId } = action.payload;
      return {
        ...state,
        selectedId: selectedProjectId,
      };
    });
    builder.addMatcher(isAnyOf(submitProjectApiThunk.fulfilled, submitProjectApiThunk.rejected), (state, action) => {
      if (state.pendingSubmitRequestId !== action.meta.requestId) {
        return;
      }
      state.pendingSubmitRequestId = undefined;
    });
  },
});

export const projectActions = slice.actions;

export const getInitialized = (store: RootState) => store.projects.initialized;
export const isLoading = (store: RootState) => store.projects.numLoading > 0;
export const isSubmitting = (store: RootState) => !!store.projects.pendingSubmitRequestId;
export const isSaving = (store: RootState) => !!store.projects.pendingSaveRequestId;

export const getRecommendedProjects = (store: RootState) => store.projects.recommendations;
export const getRecommendedHeatAndCoolProjects = createSelector(getRecommendedProjects, (projects) =>
  projects.filter(isHeatAndCoolRecommendation),
);
export const getRecommendedACs = createSelector(getRecommendedProjects, (projects) =>
  projects.filter(isACOnlyRecommendation),
);
export const getRecommendedFurnaces = createSelector(getRecommendedProjects, (projects) =>
  projects.filter(isFurnaceRecommendation),
);

export const getProjectById = (store: RootState, recommendationId: number) =>
  store.projects.recommendations.find((p) => p.recommendationId === recommendationId);

export const getSelectedProjectId = (store: RootState) => store.projects.selectedId;

export const getSelectedProject: (store: RootState) => EquipmentRecommendationAPIPayload | undefined = (
  store: RootState,
) => {
  const selectedProjectId = getSelectedProjectId(store);
  if (selectedProjectId) {
    return getProjectById(store, selectedProjectId);
  }
};

export const getTonnage = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  const { systemConfiguration, furnace } = project;
  const quarterTons = systemConfiguration?.quarterTons || furnace?.quarterTons;
  if (!quarterTons) return undefined;
  return quarterTons / QUARTER_TONS_PER_TON;
};
export const getUUID = (store: RootState) => store.projects.uuid;
export const getNumUnits = (project?: EquipmentRecommendationAPIPayload) => project && project.numUnits;

const getWarranty = (project?: EquipmentRecommendationAPIPayload, model?: Equipment) => {
  if (!project || !model) return undefined;
  const warranties = project.installerWarranties || [];
  return warranties.find((warranty) => warranty.brand.name === model.brand.name);
};

export const getProjectSystemConfigurationId = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  return project.systemConfiguration?.id;
};

export const getProjectId = (project?: EquipmentRecommendationAPIPayload) => {
  return project && project.recommendationId;
};

export const getEquipmentLifetimeInMonths = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  return project.equipmentLifetime * MONTHS_PER_YEAR;
};

export const getPartsWarranty = (project?: EquipmentRecommendationAPIPayload, model?: Equipment) => {
  const warranty = getWarranty(project, model);
  return warranty?.partsWarranty;
};

export const getLaborWarranty = (project?: EquipmentRecommendationAPIPayload) => {
  const warranty = getWarranty(project, project && project.systemConfiguration?.externalModel);
  return warranty?.laborWarranty;
};

export const getDesignTemps = (store: RootState): { heating?: number; cooling?: number } =>
  store.projects.designTemps || EMPTY_DICT;

export const getDesignTemp = (store: RootState) => getDesignTemps(store).heating;

function isProject(
  projectOrSystem: EquipmentRecommendationAPIPayload | SystemConfigurationAPIPayload,
): projectOrSystem is EquipmentRecommendationAPIPayload {
  return (projectOrSystem as EquipmentRecommendationAPIPayload).productTier !== undefined;
}

export const getHeatPumpDesignType = (
  projectOrSystem: EquipmentRecommendationAPIPayload | SystemConfigurationAPIPayload,
) => {
  if (isProject(projectOrSystem) && projectOrSystem.furnace) {
    return HeatPumpDesignType.FURNACE_ONLY;
  }
  const systemConfiguration = isProject(projectOrSystem) ? projectOrSystem.systemConfiguration : projectOrSystem;
  if (!systemConfiguration) return undefined;
  const isAcSystemFlag = isAcSystem(systemConfiguration);
  const { systemType, coil, furnace, coldClimate } = systemConfiguration;
  if (isAcSystemFlag) {
    if (coil) {
      if (furnace) {
        return HeatPumpDesignType.AC_AND_FURNACE;
      }
      return HeatPumpDesignType.AC;
    }
    return HeatPumpDesignType.AC_AND_AH;
  }
  if (coil) {
    if (furnace) {
      return HeatPumpDesignType.HYBRID_HEAT_PUMP_AND_FURNACE;
    }
    return HeatPumpDesignType.HYBRID_HEAT_PUMP;
  }
  if (coldClimate) {
    if (systemType === SystemType.SINGLE_ROOM) {
      return HeatPumpDesignType.COLD_CLIMATE_SINGLE_ZONE;
    }
    if (systemType === SystemType.MULTIPLE_ROOMS) {
      return HeatPumpDesignType.COLD_CLIMATE_MULTI_ZONE;
    }
    return HeatPumpDesignType.COLD_CLIMATE_HEAT_PUMP;
  }
  if (systemType === SystemType.SINGLE_ROOM) {
    return HeatPumpDesignType.REGULAR_SINGLE_ZONE;
  }
  if (systemType === SystemType.MULTIPLE_ROOMS) {
    return HeatPumpDesignType.REGULAR_MULTI_ZONE;
  }
  return HeatPumpDesignType.REGULAR_HEAT_PUMP;
};

export const getIndoorUnitCount = (project: EquipmentRecommendationAPIPayload) => {
  const { internalModelCounts } = project;
  const count = reduce(internalModelCounts, (sum, imc) => sum + imc.numUnits, 0);
  return count;
};

// TODO calculate this value on the backend
export const getPayoffPeriod = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  const { utilitySavings, total } = project;
  if (!utilitySavings || total === undefined) return undefined;

  const yearsToPayoff = total / (utilitySavings / SAVING_LIFESPAN);
  if (yearsToPayoff > SAVING_LIFESPAN) {
    return undefined;
  }
  return round(yearsToPayoff);
};

export const getProjectPhotos = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return EMPTY_LIST;

  const { systemConfiguration, furnace, internalModelOptions } = project;
  if (!systemConfiguration) {
    return furnace?.photos || EMPTY_LIST;
  }
  const { photos: systemPhotos } = systemConfiguration;
  const { photos: imoPhotos } = internalModelOptions || {};
  return concat(imoPhotos || [], systemPhotos || []) || EMPTY_LIST;
};

export const getProjectNoise = (project: EquipmentRecommendationAPIPayload) => {
  return project.systemConfiguration?.externalModel.noiseMin;
};

export const getProjectTotal = (project: EquipmentRecommendationAPIPayload): number => {
  return project.total || 0;
};

export const getProjectInstallerId = (project: EquipmentRecommendationAPIPayload) => {
  return project?.priceSnapshot.installer.id;
};

export const isAcSystem = (systemConfiguration?: SystemConfigurationAPIPayload) => {
  if (!systemConfiguration) return false;
  return systemConfiguration.productType === ProductType.AIR_CONDITIONER;
};

export const isACOnlyRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  const { systemConfiguration } = recommendation;
  if (!systemConfiguration || systemConfiguration.furnace) return false;
  return isAcSystem(systemConfiguration);
};

export const isHeatAndCoolRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  const { systemConfiguration } = recommendation;
  if (!systemConfiguration) return false;
  return !isAcSystem(systemConfiguration) || systemConfiguration.furnace;
};

export const isFurnaceRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  return !!recommendation.furnace;
};

export default slice.reducer;
