import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assign } from 'lodash-es';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from '.';
import { fetchPartnerApiThunk } from '../../api/PartnerAPI';
import type {
  ContentOverride,
  FurnaceTypeContentOverrideValues,
  GlobalOverride,
  HideInputsContentOverrideValues,
  IQSettings,
  LayoutTypeContentOverrideValues,
  ModalOverride,
  PartnerSettings,
  ProjectFilterContentOverrideValues,
  ProjectGroupTitlesContentOverrides,
  ServiceTypeContentOverrideValues,
  StepName,
  StepOverride,
  UiState,
} from '../../typedefs';
import {
  DEFAULT_INSTALLER_TIMEFRAME,
  EDEN_PARTNER_SLUG,
  EMPTY_DICT,
  EMPTY_LIST,
  STEP_NAMES,
} from '../../utils/constants';
import type { LayoutType, ModalType, RebateIncomeQualificationCriteria, StepType } from '../../utils/enums';
import StepUtil from '../../utils/stepUtil';

const GLOBAL_CONTENT_OVERRIDES_KEY = 'GLOBAL';

const initialState: UiState = { partnerSlug: '' };

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    initializeUiStateForPartnerSlug(state: UiState, action: PayloadAction<string>) {
      return { ...state, partnerSlug: action.payload };
    },
    initializeUiStateForEden(state, action: PayloadAction<void>) {
      return { ...state, partnerSlug: EDEN_PARTNER_SLUG, partnerDataLoaded: true };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPartnerApiThunk.fulfilled, (state, action) => {
      const { partnerSlug } = state;
      const partnerSettings = {
        ...action.payload,
        hideHowItWorks: true,
        showPoweredByEden: true,
      };
      return {
        partnerSlug,
        partnerDataLoaded: true,
        partnerSettings,
      };
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.ui,
      };
    });
  },
});

export const getUiStore = (store: RootState) => store.ui;

export const isWhiteLabelMode = (store: RootState): boolean => {
  return store.ui.partnerSlug !== EDEN_PARTNER_SLUG;
};

export const isPartnerDataLoaded = (store: RootState): boolean => {
  return !!store.ui.partnerDataLoaded;
};

export const getPartnerSlug = (store: RootState): string => {
  return store.ui.partnerSlug;
};

export const getPartnerSettings = (store: RootState): Partial<PartnerSettings> => {
  return store.ui.partnerSettings || EMPTY_DICT;
};

export const getPartnerEligibleForIncomeBracketedRebates = (store: RootState): boolean =>
  store.ui.partnerSettings?.eligibleForIncomeBracketedRebates || false;

export const getPartnerPossibleIncomeQualificationCriteria = (
  store: RootState,
): RebateIncomeQualificationCriteria[] => {
  return store.ui.partnerSettings?.possibleIncomeQualificationCriteria || EMPTY_LIST;
};

export const getIQSettings = (store: RootState): Partial<IQSettings> => {
  return getPartnerSettings(store).iqSettings || EMPTY_DICT;
};

export const showCash = (store: RootState): Partial<IQSettings> => {
  return getPartnerSettings(store).iqSettings || EMPTY_DICT;
};

const getStepOverridesWithSpecialCases = (
  contentOverride: ContentOverride,
  stepName: StepName,
): StepOverride | undefined => {
  const override = contentOverride[stepName] as StepOverride | undefined;

  if (stepName === STEP_NAMES.ENERGY_INFO) {
    const hvacDataOverrides = contentOverride['HVACData' as StepName] as StepOverride | undefined;
    return assign({}, override, hvacDataOverrides);
  }

  return override;
};

export const getStepOverrides = (
  store: RootState,
  stepNameOrType: StepName | StepType | undefined,
  layoutType?: LayoutType,
): StepOverride => {
  const { contentOverride } = getPartnerSettings(store);
  if (!contentOverride || !stepNameOrType) {
    return EMPTY_DICT;
  }

  const effectiveLayoutType = layoutType || store.formData.fields.layoutType;
  const effectiveStepName = StepUtil.ensureStepName(stepNameOrType, effectiveLayoutType);
  const override = getStepOverridesWithSpecialCases(contentOverride, effectiveStepName);
  return override || EMPTY_DICT;
};

export const getModalOverrides = (store: RootState, modalType: ModalType | undefined): ModalOverride => {
  const { contentOverride } = getPartnerSettings(store);
  if (!contentOverride || !modalType) {
    return EMPTY_DICT;
  }

  const override = contentOverride[modalType] as ModalOverride;
  return override || EMPTY_DICT;
};

export const getGlobalContentOverrides = createSelector(getPartnerSettings, ({ contentOverride }): GlobalOverride => {
  if (!contentOverride) return EMPTY_DICT;

  const override = contentOverride[GLOBAL_CONTENT_OVERRIDES_KEY] as GlobalOverride;
  return override || EMPTY_DICT;
});

export const getStepHeaderOverride = (store: RootState, stepNameOrType: StepName | StepType): string | undefined => {
  return getStepOverrides(store, stepNameOrType).stepHeader;
};

export const getStepHideInputs = (
  store: RootState,
  stepNameOrType: StepName | StepType,
): HideInputsContentOverrideValues[] | undefined => {
  return getStepOverrides(store, stepNameOrType).hideInputs;
};

export const getStepNextButtonLabelOverride = (
  store: RootState,
  stepNameOrType: StepName | StepType,
  layoutType?: LayoutType,
): string | undefined => {
  return getStepOverrides(store, stepNameOrType, layoutType).nextButtonLabel;
};

export const getPriceDisclaimerOverride = (store: RootState): string | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).priceDisclaimer;
};

export const getInstallerTimeframeOverride = (store: RootState): string => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).timeframe || DEFAULT_INSTALLER_TIMEFRAME;
};

export const getBottomDisclaimerOverride = (store: RootState): string | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).bottomDisclaimer;
};

export const getProjectGroupTitlesOverride = (store: RootState): ProjectGroupTitlesContentOverrides => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).projectGroupTitles || EMPTY_DICT;
};

export const getMultiRoomMaxNumberOfRoomsOverride = (store: RootState): number | undefined => {
  return getStepOverrides(store, STEP_NAMES.MULTI_ROOM).maxNumberOfRooms;
};

export const getAppSettings = (store: RootState): IQSettings => {
  const { iqSettings } = getPartnerSettings(store);
  return iqSettings || EMPTY_DICT;
};

export const getDefaultZip = (store: RootState): number => {
  return getPartnerSettings(store)?.defaultZip || 94016;
};

export const getFinancingOptionsModalDescriptionOverride = (store: RootState): string | undefined => {
  const overrides = getStepOverrides(store, STEP_NAMES.RESULTS);
  return overrides.financingOptionsModalDescription;
};

export const getSupportedServicesOverride = (store: RootState): ServiceTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.supportedServices || EMPTY_LIST;
};

export const getSupportedLayoutsOverride = (store: RootState): LayoutTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.supportedLayouts || EMPTY_LIST;
};

export const getSupportedFurnaceTypesOverride = (store: RootState): FurnaceTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.ENERGY_INFO);
  return overrides.supportedFurnaceTypes || EMPTY_LIST;
};

export const getSupportedProjectFiltersOverride = (store: RootState): ProjectFilterContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.supportedProjectFilters || EMPTY_LIST;
};

export const getShowContactFormForOverride = (store: RootState): LayoutTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.showContactFormFor || EMPTY_LIST;
};

export const getSecondaryTrackingId = (store: RootState): string | undefined => {
  const appSettings = getAppSettings(store);
  return appSettings.secondaryTrackerId;
};

export const uiActions = slice.actions;

export default slice.reducer;
