/* eslint-disable import/prefer-default-export */

export enum ModalType {
  UTILITY = 'utility',
  QUOTE = 'quote',
  COOLING = 'cooling',
  COMPATIBILITY = 'compatibility',
  AC_COMPATIBILITY = 'ac-compatibility',
  ROOM_SIZE = 'room-size',
  ROOM_SELECTION = 'room-selection',
  HSPF = 'hspf',
  SEER = 'seer',
  SYSTEM_COMPARISON = 'system-comparison',
  REBATES = 'rebates',
  FINANCING = 'financing',
  CARBON = 'carbon',
  SIZING = 'sizing',
  NOISE = 'noise',
  BRAND = 'brand',
  COMFORT = 'comfort',
  SYSTEM = 'system',
  COLD_CLIMATE = 'cold-climate',
  FURNACE_CAPACITY = 'furnace-capacity',
  FURNACE_AFUE = 'furnace-afue',
  FURNACE_BLOWER_SPEED = 'furnace-blower-speed',
  FURNACE_BURNER_STAGE = 'furnace-burner-stage',
  HOUSEHOLD_INCOME_HELP = 'household-income-help',
}

/** @public */
export enum Fuel {
  ELECTRIC_KWH,
  HEATING_OIL_GAL,
  PROPANE_GAL,
  NATURAL_GAS_THERM,
}

/** @public */
export enum FurnaceType {
  ELECTRIC_RESISTANCE,
  ELECTRIC_HEAT_PUMP,
  OIL,
  PROPANE,
  NATURAL_GAS,
}

/** @public */
export enum DuctedDeliveryType {
  YES,
  NO,
  UNKNOWN,
}

/** @public */
export enum ServiceType {
  REPAIR,
  REPLACE, // no longer presented as an option in the UI
  NEW,
}

/** @public */
export enum LayoutType {
  WHOLE_HOME,
  MULTIPLE_ROOMS,
  SINGLE_ROOM,
  UNKNOWN,
  REPLACE_EXISTING, // no longer presented as an option in the UI
  BOILER,
}

/** @public */
export enum NumStoriesType {
  ONE = 1,
  ONE_AND_A_HALF = 1.5,
  TWO = 2,
  TWO_AND_A_HALF = 2.5,
  THREE = 3,
  THREE_AND_A_HALF = 3.5,
  FOUR = 4,
  MORE_THAN_FOUR = 5,
}

/** @public */
export enum FoundationType {
  HEATED_BASEMENT = 1,
  UNHEATED_BASEMENT,
  VENTED_CRAWLSPACE,
  UNVENTED_CRAWLSPACE,
  AMBIENT,
  SLAB,
}

/** @public */
export enum BuildingMaterialType {
  WOOD,
  BRICK,
  CONCRETE,
  CINDER_BLOCKS,
  OTHER,
}

/** @public */
export enum WholeHomeSystem {
  YES,
  NO,
  UNKNOWN,
}

/** @public */
export enum NumExistingSystems {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  UNKNOWN = 999999,
}

/** @public */
export enum ExistingAcTonnage {
  ONE_AND_A_HALF = 1.5,
  TWO = 2,
  TWO_AND_A_HALF = 2.5,
  THREE = 3,
  THREE_AND_A_HALF = 3.5,
  FOUR = 4,
  FOUR_AND_A_HALF = 4.5,
  FIVE = 5,
  UNKNOWN = 999999,
}

/** @public */
export enum YesNoType {
  YES,
  NO,
  UNKNOWN,
}

/** @public */
export enum ProjectFilter {
  HEAT_AND_COOL = 1,
  HEAT_ONLY,
  COOL_ONLY,
}

// This used to exist on the back-end, but is no longer defined there.
export enum HeatPumpDesignType {
  COLD_CLIMATE_HEAT_PUMP = 1,
  COLD_CLIMATE_SINGLE_ZONE,
  COLD_CLIMATE_MULTI_ZONE,
  REGULAR_HEAT_PUMP,
  REGULAR_SINGLE_ZONE,
  REGULAR_MULTI_ZONE,
  HYBRID_HEAT_PUMP,
  HYBRID_HEAT_PUMP_AND_FURNACE,
  AC,
  AC_AND_FURNACE,
  AC_AND_AH,
  FURNACE_ONLY,
}

export enum ComfortType {
  GOOD = 1,
  EXCEPTIONAL,
}

export enum ClimateType {
  STANDARD = 1,
  COLD_CLIMATE,
  DUAL_FUEL,
  FURNACE,
  HEAT_STRIPS,
}

export const ClimateTypeLabel = {
  [ClimateType.STANDARD]: '17°F to 110°F',
  [ClimateType.COLD_CLIMATE]: '-13°F to 110°F',
  [ClimateType.DUAL_FUEL]: 'Below zero to 110°F',
  [ClimateType.FURNACE]: 'Below zero to 110°F',
};

export enum HeatPumpSystemType {
  UNITARY = 1,
  MINI_SPLIT,
  DUAL_FUEL,
}

/** @public */
export enum ProductType {
  HEAT_PUMP = 1,
  AIR_CONDITIONER,
}

/** @public */
export enum SystemType {
  MULTIPLE_ROOMS,
  SINGLE_ROOM,
  UNITARY_DUCTED,
}

export const SystemTypeLabel = {
  [SystemType.UNITARY_DUCTED]: 'Standalone',
  [SystemType.MULTIPLE_ROOMS]: 'Room by room',
  [SystemType.SINGLE_ROOM]: 'SingleRoom',
};

/** @public */
export enum CompressorStageType {
  ONE = 1,
  TWO = 2,
  MODULATING = 98,
  VARIABLE = 99,
}

/** @public */
export enum BurnerStageType {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  MODULATING = 98,
  VARIABLE = 99,
}

export const BURNER_STAGE_LABEL = {
  [BurnerStageType.ONE]: 'Single Stage',
  [BurnerStageType.TWO]: 'Two Stage',
  [BurnerStageType.THREE]: 'Three Stage',
  [BurnerStageType.MODULATING]: 'Modulating',
  [BurnerStageType.VARIABLE]: 'Variable Stage',
};

/** @public */
export enum BlowerSpeedType {
  ONE = 1,
  TWO = 2,
  MULTI = 97,
  MODULATING = 98,
  VARIABLE = 99,
}

export const BLOWER_SPEED_LABEL = {
  [BlowerSpeedType.ONE]: 'Single Speed',
  [BlowerSpeedType.TWO]: 'Two Speed',
  [BlowerSpeedType.MULTI]: 'Multi-Speed',
  [BlowerSpeedType.MODULATING]: 'Modulating',
  [BlowerSpeedType.VARIABLE]: 'Variable Speed',
};

export const HeatPumpTypeInfo = {
  [HeatPumpDesignType.COLD_CLIMATE_HEAT_PUMP]: {
    label: 'Cold-Climate Heat Pump',
    climateType: ClimateType.COLD_CLIMATE,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
  [HeatPumpDesignType.REGULAR_HEAT_PUMP]: {
    label: 'Electric Heat Pump',
    climateType: ClimateType.STANDARD,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
  [HeatPumpDesignType.HYBRID_HEAT_PUMP]: {
    label: 'Dual Fuel Heat Pump',
    climateType: ClimateType.DUAL_FUEL,
    heatPumpSystemType: HeatPumpSystemType.DUAL_FUEL,
  },
  [HeatPumpDesignType.HYBRID_HEAT_PUMP_AND_FURNACE]: {
    label: 'Dual Fuel Heat Pump',
    climateType: ClimateType.DUAL_FUEL,
    heatPumpSystemType: HeatPumpSystemType.DUAL_FUEL,
  },
  [HeatPumpDesignType.AC]: {
    label: 'Air Conditioner',
    climateType: ClimateType.FURNACE,
    heatPumpSystemType: HeatPumpSystemType.DUAL_FUEL,
  },
  [HeatPumpDesignType.AC_AND_FURNACE]: {
    label: 'Air Conditioner And Furnace',
    climateType: ClimateType.FURNACE,
    heatPumpSystemType: HeatPumpSystemType.DUAL_FUEL,
  },
  [HeatPumpDesignType.AC_AND_AH]: {
    label: 'Air Conditioner And Fan Coil',
    climateType: ClimateType.HEAT_STRIPS,
    heatPumpSystemType: HeatPumpSystemType.DUAL_FUEL,
  },
  [HeatPumpDesignType.COLD_CLIMATE_SINGLE_ZONE]: {
    label: 'Cold-Climate Single Zone Mini-Split',
    climateType: ClimateType.COLD_CLIMATE,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
  [HeatPumpDesignType.REGULAR_SINGLE_ZONE]: {
    label: 'Single Zone Mini-Split',
    climateType: ClimateType.STANDARD,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
  [HeatPumpDesignType.COLD_CLIMATE_MULTI_ZONE]: {
    label: 'Cold-Climate Multi-Zone Mini-Split',
    climateType: ClimateType.COLD_CLIMATE,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
  [HeatPumpDesignType.REGULAR_MULTI_ZONE]: {
    label: 'Multi-Zone Mini-Split',
    climateType: ClimateType.STANDARD,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
  [HeatPumpDesignType.FURNACE_ONLY]: {
    label: 'Furnace',
    climateType: ClimateType.FURNACE,
    heatPumpSystemType: HeatPumpSystemType.UNITARY,
  },
};

/** @public */
export enum LineItemType {
  OTHER,
  MATERIAL,
  LABOR,
  ELECTRICAL,
  EQUIPMENT,
}

export enum StepType {
  INTRO = 1,
  LOCATION,
  BUILDING_INFO,
  ROOM_INFO,
  ENERGY_INFO,
  HOUSEHOLD,
  RESULTS,
}

export const STEP_LABEL = {
  [StepType.INTRO]: 'Intro',
  [StepType.LOCATION]: 'Location',
  [StepType.BUILDING_INFO]: 'Building Info',
  [StepType.ROOM_INFO]: 'Room Info',
  [StepType.ENERGY_INFO]: 'Energy Info',
  [StepType.HOUSEHOLD]: 'Household',
  [StepType.RESULTS]: 'Results',
};

export const FuelForFurnaceType = {
  [FurnaceType.ELECTRIC_RESISTANCE]: Fuel.ELECTRIC_KWH,
  [FurnaceType.ELECTRIC_HEAT_PUMP]: Fuel.ELECTRIC_KWH,
  [FurnaceType.OIL]: Fuel.HEATING_OIL_GAL,
  [FurnaceType.PROPANE]: Fuel.PROPANE_GAL,
  [FurnaceType.NATURAL_GAS]: Fuel.NATURAL_GAS_THERM,
};

/** @public */
export enum PaymentOptionType {
  CASH,
  LOAN,
}

/** @public */
export enum InstallerStatus {
  OFF, // inaccessible in white label and in EDEN IQ
  EDEN_ONLY, // accessible in EDEN IQ only
  WL_ONLY, // accessible in white label only
  ALL_ON, // accessible in white label and in EDEN IQ
}

/** @public */
export enum UploadInstallerPricesBehavior {
  IGNORE_CURRENT, // Preserve the current pricing
  EXPIRE_CURRENT, // Expire ALL current pricing but preserve the underlying data
  REPLACE_CURRENT, // Delete ALL current pricing before adding the new prices
}

/** @public */
export enum UploadInstallersBehavior {
  ADD_NEW_ONLY, // Add new installers only, preserve the current installers
  ADD_AND_UPDATE_EXISTING, // Add new installers, and update ALL fields for matching existing installers
}

export enum CostDiscountType {
  TAX_CREDIT = 'tax_credit',
  REBATE = 'rebate',
  DISCOUNT = 'discount',
  INCENTIVE = 'incentive',
}

export const COST_DISCOUNT_LABELS: { [key in CostDiscountType]: string } = {
  [CostDiscountType.TAX_CREDIT]: 'tax credit',
  [CostDiscountType.REBATE]: 'rebate',
  [CostDiscountType.DISCOUNT]: 'discount',
  [CostDiscountType.INCENTIVE]: 'incentive',
};

/** @public */
export enum RebateProviderType {
  UTILITY = 1,
  MIDSTREAM,
  GOVERNMENT,
}

/** @public */
export enum RebateIncomeQualificationCriteria {
  HUD_AMI_BELOW_OR_AT_30PCT = 1,
  HUD_AMI_BELOW_OR_AT_50PCT = 2,
  HUD_AMI_BELOW_OR_AT_80PCT = 3,
  HUD_AMI_BELOW_OR_AT_100PCT = 4,
  HUD_AMI_BELOW_OR_AT_150PCT = 5,
}

export const REBATE_INCOME_QUALIFICATION_CRITERIA_FOR_HUD_AMI_KEYS = {
  extremelyLow: RebateIncomeQualificationCriteria.HUD_AMI_BELOW_OR_AT_30PCT,
  fiftyPct: RebateIncomeQualificationCriteria.HUD_AMI_BELOW_OR_AT_50PCT,
  eightyPct: RebateIncomeQualificationCriteria.HUD_AMI_BELOW_OR_AT_80PCT,
  oneHundredPct: RebateIncomeQualificationCriteria.HUD_AMI_BELOW_OR_AT_100PCT,
  oneHundredFiftyPct: RebateIncomeQualificationCriteria.HUD_AMI_BELOW_OR_AT_150PCT,
};

/** @public */
export enum SelectedHudAmi {
  HUD_AMI_BELOW_OR_AT_30PCT = 1,
  HUD_AMI_BELOW_OR_AT_50PCT = 2,
  HUD_AMI_BELOW_OR_AT_80PCT = 3,
  HUD_AMI_BELOW_OR_AT_100PCT = 4,
  HUD_AMI_BELOW_OR_AT_150PCT = 5,
  // The above entries must match HUD AMI values in RebateIncomeQualificationCriteria above
  HUD_AMI_ABOVE_150PCT = 1111,
  UNKNOWN = 9999,
}

/** @public */
export enum UtilityProviderServiceType {
  ELECTRIC = 1,
  GAS,
  STEAM,
  WATER,
}

/** @public */
export enum ACDiscountType {
  GUARANTEED,
  POSSIBLE,
}

/**
 * @public
 * Only used in white label mode
 */
export enum InstallerPricePresentationMode {
  DISPLAY_LINE_ITEM_PRICES = 1, // This matches default IQ mode
  HIDE_LINE_ITEM_PRICES, // Only the total will be displayed
}

/** @public */
export enum UploadEquipmentPhotosBehavior {
  IGNORE_CURRENT, // Preserve the current photo mappings
  REPLACE_CURRENT, // Delete ALL current photo mappings before adding the new photo mappings
}

/** @public */
export enum ProductTier {
  GOOD = 1,
  BETTER,
  BEST,
  FANTASTIC,
  DRAFT,
}

export const PRODUCT_TIER_LABELS = {
  [ProductTier.GOOD]: 'Good',
  [ProductTier.BETTER]: 'Better',
  [ProductTier.BEST]: 'Best',
  [ProductTier.FANTASTIC]: 'Fantastic',
  [ProductTier.DRAFT]: 'Draft',
};

export enum SystemDifferentiator {
  UPFRONT_COST = 1,
  UTILTIY_SAVINGS,
  NOISE_LEVEL,
  COLD_CLIMATE,
  DUAL_FUEL,
  MOST_COMFORTABLE,
  CARBON_SAVINGS,
}

export const SYSTEM_DIFFERENTIATOR_LABELS = {
  [SystemDifferentiator.UPFRONT_COST]: 'Budget-Friendly',
  [SystemDifferentiator.UTILTIY_SAVINGS]: 'Best Utility Savings',
  [SystemDifferentiator.NOISE_LEVEL]: 'Whisper Quiet',
  [SystemDifferentiator.COLD_CLIMATE]: 'Arctic Ready',
  [SystemDifferentiator.DUAL_FUEL]: 'Dual Fuel',
  [SystemDifferentiator.MOST_COMFORTABLE]: 'Most Comfortable',
  [SystemDifferentiator.CARBON_SAVINGS]: 'Eco-Friendly',
};

/** @public */
export enum Auth0IdentitySource {
  AUTH0 = 'auth0',
  GOOGLE = 'google',
}

/** @public */
export enum Role {
  ADMIN = 'Admin',
}

/** @public */
export enum Permission {
  UPDATE_PASSWORD = 'update:password',
  READ_ALL = 'read:*',
  WRITE_ALL = 'write:*',
}

/** @public */
export enum ExternalCredentialsType {
  SERVICE_TITAN = 1,
}

export const EXTERNAL_CREDENTIALS_TYPE_LABELS = {
  [ExternalCredentialsType.SERVICE_TITAN]: 'Service Titan',
};

/** @public */
export enum ContactEmailType {
  REPAIR = 'repair',
  BOILER = 'boiler',
  WHOLE_HOME = 'whole-home',
  MULTIPLE_ROOMS = 'multiple-rooms',
  SINGLE_ROOM = 'single-room',
}
