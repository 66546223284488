import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { head, includes, mapValues, set } from 'lodash-es';
import type { RootState } from '.';
import { reloadProjectApiThunk, submitContactFormApiThunk } from '../../api/ProjectsAPI';
import { fetchPropertyApiThunk } from '../../api/PropertyDataAPI';
import type { FormAPIPayload, FormFields, FormState, PlaceData } from '../../typedefs';
import {
  FORM_FIELDS_THAT_DEFAULT_TO_ZERO,
  INITIAL_EXISTING_SEER,
  INITIAL_MAX_TEMPERATURE_DAY,
  INITIAL_MAX_TEMPERATURE_NIGHT,
  INITIAL_MIN_TEMPERATURE_DAY,
  INITIAL_MIN_TEMPERATURE_NIGHT,
} from '../../utils/constants';
import {
  DuctedDeliveryType,
  ExistingAcTonnage,
  FurnaceType,
  LayoutType,
  NumExistingSystems,
  NumStoriesType,
  ProjectFilter,
  ServiceType,
  WholeHomeSystem,
} from '../../utils/enums';
import NumberUtil from '../../utils/numberUtil';
import StepUtil from '../../utils/stepUtil';
import {
  FormUpdate,
  formUpdateAction,
  NestedFieldUpdate,
  nestedFieldUpdateAction,
  SelectorUpdate,
  selectorUpdateAction,
} from './sharedActions';

const initialState: FormState = {
  fields: {
    sqFt: '',
    zipCode: '',
    autocompleteZipCode: '',
    layoutType: LayoutType.WHOLE_HOME,
    serviceType: ServiceType.NEW,
    projectFilter: ProjectFilter.HEAT_AND_COOL,

    numExistingSystems: NumExistingSystems.ONE,
    existingAcTonnage: ExistingAcTonnage.UNKNOWN,

    minTempDay: `${INITIAL_MIN_TEMPERATURE_DAY}`,
    minTempNight: `${INITIAL_MIN_TEMPERATURE_NIGHT}`,
    maxTempDay: `${INITIAL_MAX_TEMPERATURE_DAY}`,
    maxTempNight: `${INITIAL_MAX_TEMPERATURE_NIGHT}`,

    existingSeer: `${INITIAL_EXISTING_SEER}`,

    yearBuilt: '',

    streetAddress: '',
    email: '',
    homeownerProjectNotes: '',
    firstName: '',
    lastName: '',
    phone: '',
    roomSqFt: '',
    totalSmall: '0',
    upperSmall: '0',
    totalMedium: '0',
    upperMedium: '0',
    totalLarge: '0',
    upperLarge: '0',
    totalXLarge: '0',
    upperXLarge: '0',
    addedFurnace: 'false',
  },

  metaData: {
    layoutInitialized: false,
    contactFormSubmittedSuccess: false,
    isInitialState: true,
    touched: {},
  },
};

const emptyAutocompleteData: PlaceData = {
  streetNumber: '',
  route: '',
  neighborhood: '',
  city: '',
  county: '',
  state: '',
  country: '',
  zipCode: '',
  zipSuffix: '',
};

function numberToString(num?: number, padLeadingZerosToWidth?: number) {
  return NumberUtil.toString(num, { padLeadingZerosToWidth }) || '';
}

function getNumStoriesType(num?: number): NumStoriesType | undefined {
  if (!num || num < 1) {
    return undefined;
  }
  if (num > 4) {
    return NumStoriesType.MORE_THAN_FOUR;
  }
  return num;
}

function isFieldTouched(key: string, value: string | number | undefined) {
  if (includes(FORM_FIELDS_THAT_DEFAULT_TO_ZERO, key)) {
    return value !== undefined && value !== 0;
  } else {
    return value !== undefined;
  }
}

function determineTouchedFields(touchableFields: Partial<FormAPIPayload>) {
  return mapValues(touchableFields, (value, key) => {
    if (key === 'projectFilters') {
      return isFieldTouched(key, head(value as any[] | undefined));
    }

    // This "as any" is dumb. There should be a better way to exclude the array type for projectFilters.
    return isFieldTouched(key, value as any);
  });
}

function updateIsInitialStateToFalse(state: FormState): FormState {
  const { metaData } = state;
  return {
    ...state,
    metaData: { ...metaData, isInitialState: false },
  };
}

function determineWholeHomeSystem(furnaceType: FurnaceType | undefined, wholeHomeSystem: WholeHomeSystem | undefined) {
  return furnaceType === FurnaceType.ELECTRIC_HEAT_PUMP ? WholeHomeSystem.YES : wholeHomeSystem;
}

const slice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    setPlaceData(state, action: PayloadAction<PlaceData>) {
      const { metaData, fields } = state;
      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, ...action.payload, autocompleteZipCode: action.payload.zipCode },
        metaData: {
          ...metaData,
          touched: { ...metaData.touched, zipCode: true, streetAddress: true },
        },
      });
    },
    clearAutocompleteData(state) {
      const { fields } = state;
      const { zipCode, ...emptyData } = emptyAutocompleteData;
      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, ...emptyData, autocompleteZipCode: zipCode },
      });
    },
    initializeLayoutTypeFromPathname(state, action: PayloadAction<string | undefined>) {
      const { metaData, fields } = state;
      const { payload } = action;
      const layoutType = StepUtil.getPathFromUrl(payload);
      if (!layoutType) {
        return state;
      }
      return {
        ...state,
        fields: { ...fields, layoutType },
        metaData: { ...metaData, layoutInitialized: true },
      };
    },
    updateFurnace(state, action: PayloadAction<FurnaceType>) {
      const furnaceType = action.payload;
      const { metaData, fields } = state;

      const { wholeHomeSystem } = fields;
      const updateWholeHomeSystem = determineWholeHomeSystem(furnaceType, wholeHomeSystem);

      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, furnaceType, wholeHomeSystem: updateWholeHomeSystem },
        metaData: { ...metaData, touched: { ...metaData.touched, furnaceType: true } },
      });
    },
    updateDuctedDelivery(state, action: PayloadAction<DuctedDeliveryType>) {
      const { metaData, fields } = state;
      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, ductedDelivery: action.payload },
        metaData: { ...metaData, touched: { ...metaData.touched, ductedDelivery: true } },
      });
    },
    updateLayoutType(state, action: PayloadAction<LayoutType>) {
      const { metaData, fields } = state;
      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, layoutType: action.payload },
        metaData: { ...metaData, touched: { ...metaData.touched, layoutType: true } },
      });
    },
    updateServiceType(state, action: PayloadAction<ServiceType>) {
      const { metaData, fields } = state;
      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, serviceType: action.payload },
        metaData: { ...metaData, touched: { ...metaData.touched, serviceType: true } },
      });
    },
    updateProjectFilter(state, action: PayloadAction<ProjectFilter>) {
      const { metaData, fields } = state;
      return updateIsInitialStateToFalse({
        ...state,
        fields: { ...fields, projectFilter: action.payload },
        metaData: { ...metaData, touched: { ...metaData.touched, projectFilter: true } },
      });
    },
    setIsInitialFormDataStateToFalse(state) {
      return updateIsInitialStateToFalse(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(formUpdateAction, (state, action: PayloadAction<FormUpdate>) => {
      const { field, value } = action.payload;
      // Leverages redux toolkit Immer
      set(state.fields, field, value);
      set(state.metaData, field, true);
      state.metaData.isInitialState = false;
    });
    builder.addCase(selectorUpdateAction, (state, action: PayloadAction<SelectorUpdate>) => {
      const { field, value } = action.payload;
      // Leverages redux toolkit Immer
      set(state.fields, field, value);
      set(state.metaData, field, true);
      state.metaData.isInitialState = false;
    });
    builder.addCase(submitContactFormApiThunk.fulfilled, (state) => {
      return {
        ...state,
        metaData: { ...state.metaData, contactFormSubmittedSuccess: true },
      };
    });
    builder.addCase(submitContactFormApiThunk.pending, (state) => {
      return {
        ...state,
        metaData: { ...state.metaData, contactFormSubmittedSuccess: false },
      };
    });
    builder.addCase(nestedFieldUpdateAction, (state, action: PayloadAction<NestedFieldUpdate>) => {
      const { field, value } = action.payload;
      // Leverages redux toolkit Immer
      set(state.fields, field, value);
      set(state.metaData, field, true);
      state.metaData.isInitialState = false;
    });
    builder.addCase(reloadProjectApiThunk.fulfilled, (state, action) => {
      const { uuid, ...touchableFields } = action.payload;
      const {
        streetAddress,
        zipSuffix,
        zipCode,
        yearBuilt,
        sqFt,
        roomSqFt,
        upperSmall,
        totalSmall,
        upperMedium,
        totalMedium,
        upperLarge,
        totalLarge,
        upperXLarge,
        totalXLarge,
        minTempDay,
        minTempNight,
        maxTempDay,
        maxTempNight,
        existingSeer,
        layoutType,
        serviceType,
        furnaceType,
        wholeHomeSystem,
        existingAcTonnage,
        projectFilters,
        ...rest
      } = touchableFields;

      // The form data is not valid if serviceType and layoutType are not set.
      if (serviceType === undefined || layoutType === undefined) return state;

      const updateWholeHomeSystem = determineWholeHomeSystem(furnaceType, wholeHomeSystem);
      const updatedExistingAcTonnage =
        existingAcTonnage === ExistingAcTonnage.FOUR_AND_A_HALF ? ExistingAcTonnage.FIVE : existingAcTonnage;

      const touched = determineTouchedFields(touchableFields);
      const updatedState = {
        ...state,
        fields: {
          ...state.fields,
          ...rest,
          zipCode: numberToString(zipCode, 5),
          zipSuffix: numberToString(zipSuffix),
          yearBuilt: numberToString(yearBuilt),
          sqFt: numberToString(sqFt),
          roomSqFt: numberToString(roomSqFt),
          upperSmall: numberToString(upperSmall),
          totalSmall: numberToString(totalSmall),
          upperMedium: numberToString(upperMedium),
          totalMedium: numberToString(totalMedium),
          upperLarge: numberToString(upperLarge),
          totalLarge: numberToString(totalLarge),
          upperXLarge: numberToString(upperXLarge),
          totalXLarge: numberToString(totalXLarge),
          minTempDay: numberToString(minTempDay),
          minTempNight: numberToString(minTempNight),
          maxTempDay: numberToString(maxTempDay),
          maxTempNight: numberToString(maxTempNight),
          existingSeer: numberToString(existingSeer),
          streetAddress: streetAddress || '',
          layoutType: state.metaData.layoutInitialized ? state.fields.layoutType : layoutType,
          serviceType,
          projectFilter: head(projectFilters),
          furnaceType,
          wholeHomeSystem: updateWholeHomeSystem,
          existingAcTonnage: updatedExistingAcTonnage,
        },
        metaData: { ...state.metaData, uuid, touched },
      };

      return updateIsInitialStateToFalse(updatedState);
    });
    builder.addCase(fetchPropertyApiThunk.pending, (state, action) => {
      // Leverages redux toolkit Immer
      state.metaData.loadingPropertyRequestId = action.meta.requestId;
    });
    builder.addCase(fetchPropertyApiThunk.rejected, (state, action) => {
      // Leverages redux toolkit Immer
      if (action.meta.requestId !== state.metaData.loadingPropertyRequestId) {
        return state;
      }
      state.metaData.loadingPropertyRequestId = undefined;
    });
    builder.addCase(fetchPropertyApiThunk.fulfilled, (state, action) => {
      // Leverages redux toolkit Immer
      if (action.meta.requestId !== state.metaData.loadingPropertyRequestId) {
        return state;
      }
      state.metaData.loadingPropertyRequestId = undefined;
      const { payload } = action;
      if (!payload) {
        return;
      }
      const { fields, metaData } = state;
      const {
        sqFt,
        yearBuilt,
        numStories: numStoriesVal,
        ductedDelivery,
        furnaceType,
        wholeHomeSystem,
        ceilingHeightInFt,
        basementSqFt,
        firstFloorSqFt,
        secondFloorSqFt,
        thirdFloorSqFt,
        numBedrooms,
        numFireplaces,
        coolingDaySetpoint,
        coolingNightSetpoint,
        heatingDaySetpoint,
        heatingNightSetpoint,
      } = payload;
      const numStories = getNumStoriesType(numStoriesVal);
      state.fields = {
        ...fields,
        sqFt: `${sqFt || ''}`,
        yearBuilt: `${yearBuilt || ''}`,
        ductedDelivery,
        furnaceType,
        wholeHomeSystem,
        numStories,
        ceilingHeightInFt: `${ceilingHeightInFt || ''}`,
        basementSqFt: `${basementSqFt || ''}`,
        firstFloorSqFt: `${firstFloorSqFt || ''}`,
        secondFloorSqFt: `${secondFloorSqFt || ''}`,
        thirdFloorSqFt: `${thirdFloorSqFt || ''}`,
        numBedrooms: `${numBedrooms || ''}`,
        numFireplaces: `${numFireplaces || ''}`,
        maxTempDay: `${coolingDaySetpoint || ''}`,
        maxTempNight: `${coolingNightSetpoint || ''}`,
        minTempDay: `${heatingDaySetpoint || ''}`,
        minTempNight: `${heatingNightSetpoint || ''}`,
      };
      state.metaData.touched = {
        ...metaData.touched,
        sqFt: sqFt !== undefined,
        yearBuilt: yearBuilt !== undefined,
        ductedDelivery: ductedDelivery !== undefined,
        furnaceType: furnaceType !== undefined,
        wholeHomeSystem: wholeHomeSystem !== undefined,
        numStories: numStories !== undefined,
        ceilingHeightInFt: ceilingHeightInFt !== undefined,
        basementSqFt: basementSqFt !== undefined,
        firstFloorSqFt: firstFloorSqFt !== undefined,
        secondFloorSqFt: secondFloorSqFt !== undefined,
        thirdFloorSqFt: thirdFloorSqFt !== undefined,
        numBedrooms: numBedrooms !== undefined,
        numFireplaces: numFireplaces !== undefined,
      };
      state.metaData.isInitialState = false;
    });
  },
});

export const formActions = {
  ...slice.actions,
  updateField: formUpdateAction,
  updateSelector: selectorUpdateAction,
  updateNestedField: nestedFieldUpdateAction,
};

export const getFormData = (state: RootState) => state.formData.fields;
export const getTouchedFields = (state: RootState) => state.formData.metaData.touched;
export const getLayoutType = (state: RootState) => state.formData.fields.layoutType;
export const getEffectiveLayoutType = (state: RootState) => {
  const serviceType = getServiceType(state);
  if (serviceType === ServiceType.REPAIR) return undefined;
  return getLayoutType(state);
};
export const getServiceType = (state: RootState) => state.formData.fields.serviceType;
export const getProjectFilter = (state: RootState) => state.formData.fields.projectFilter;
export const getAddedFurnace = (state: RootState) => state.formData.fields.addedFurnace === 'true';
export const getWholeHomeSystem = (formData: FormFields) => {
  const { furnaceType, wholeHomeSystem } = formData;
  return determineWholeHomeSystem(furnaceType, wholeHomeSystem);
};
export const getShowIncomeQualifiedRebates = (state: RootState) => state.formData.fields.showIncomeQualifiedRebates;
export const isLoadingPropertyData = (state: RootState) => !!state.formData.metaData.loadingPropertyRequestId;
export const isInitialFormDataState = (state: RootState) => !!state.formData.metaData.isInitialState;
export const isContactFormSubmitSuccess = (state: RootState) => !!state.formData.metaData.contactFormSubmittedSuccess;

export default slice.reducer;
